import React from 'react';
import { graphql } from 'gatsby';

import HeroTypeA from '../../components/Heroes/HeroTypeA';
import LocationMap from '../../components/Location/LocationMap';
import StoreFacts from '../../components/Location/StoreFacts';
import HowToOrderOnline from '../../components/Banners/HowToOrderOnline';
import CategorySection from '../../components/Category Section/CategorySection';
import BannerInfoTypeA from '../../components/Banners/BannerInfoTypeA';
// SUPER HEADER CUSTOM
import BannerTypeA from '../../components/Banners/BannerTypeA';
import LocationSearchSection from '../../components/Location/LocationSearchSection';
import FaqSection from '../../components/FAQ/FaqSection';
import SEO from '../../components/SEO/SEO';

export default function KingstonLocation({ data }) {
  const hero_type_a = data.strapiKingstonLocation.Hero;
  const hours_of_op = data.strapiKingstonLocation.Hours_Of_Op_Banner;
  const quick_store_facts = data.strapiKingstonLocation.Store_Quick_Facts;
  const category = data.strapiKingstonLocation.Category_Section;
  const info_banner_type_a = data.strapiKingstonLocation.Info_Banner_Type_A;
  const banner_type_middle = data.strapiKingstonLocation.Blog_Banner_Middle;
  const location_search =
    data.strapiLocationsSearchComponent.Location_With_Search;
  const faq = data.strapiKingstonLocation.FAQ;
  const banner_type_bottom = data.strapiKingstonLocation.Blog_Banner_Bottom;

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Store',
    name: 'House of Cannabis',
    image: '/logo.jpg',
    '@id': '',
    url: 'https://thehousecannabis.ca/kingston/location',
    telephone: '(519)-714-0420',
    priceRange: '$',
    address: {
      '@type': 'PostalAddress',
      streetAddress: '208 Division Street',
      addressLocality: 'Kingston',
      addressRegion: 'ON',
      postalCode: 'K7K 3Z1',
      addressCountry: 'CA',
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 44.2342596,
      longitude: -76.4956145,
    },
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ],
        opens: '10:00',
        closes: '21:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Sunday',
        opens: '11:00',
        closes: '18:00',
      },
    ],
  };

  return (
    <>
      <SEO
        title="The Best Cannabis Store in Kingston | House of Cannabis"
        description="Located at 208 Division Street, our team’s unwavering 
                  commitment to offering the best customer service has helped make 
                  us Kingston’s #1 weed dispensary."
        schemaMarkup={schema}
      />
      <HeroTypeA
        title={hero_type_a.Text}
        buttonLabel={hero_type_a.Button.Label}
        heroImage={
          hero_type_a.BackgroundImage.localFile.childImageSharp.gatsbyImageData
        }
        buttonLink={hero_type_a.Button.Custom_Link_Slug}
        hasGradient
      />

      <LocationMap
        title={hours_of_op.Title}
        street={hours_of_op.Address}
        hours={hours_of_op.Day_And_Hours}
        orderSlug={hours_of_op.Order_Online_Link_Slug}
        exactAddress={hours_of_op.Address_Link}
        long={hours_of_op.long}
        lat={hours_of_op.lat}
      />

      <StoreFacts
        storeFacts={quick_store_facts}
        storeHours={hours_of_op.Day_And_Hours}
      />

      <HowToOrderOnline loc_slug="/menu" />

      <CategorySection categorydata={category.Block} title={category.Title} />

      <BannerInfoTypeA
        title={info_banner_type_a.Title}
        description={info_banner_type_a.Content}
        bgImage={
          info_banner_type_a.Image.localFile.childImageSharp.gatsbyImageData
        }
      />

      <BannerTypeA
        title={banner_type_middle.Article_Link.Title}
        description={banner_type_middle.Article_Link.Preview_Text}
        bannerImage={
          banner_type_middle.Article_Link.BannerImage.localFile.childImageSharp
            .gatsbyImageData
        }
        link="/menu"
        buttonLabel="READ ARTICLE"
      />

      {/* {`/blog/${banner_type_middle.Article_Link.slug}`} */}

      <LocationSearchSection
        titles={location_search.Title}
        locations={location_search.Location_Single}
      />

      <FaqSection faqdata={faq.FAQs} title={faq.Title} />

      <BannerTypeA
        title={banner_type_bottom.Title}
        description={banner_type_bottom.Content}
        bannerImage={
          banner_type_bottom.Background_Image.localFile.childImageSharp
            .gatsbyImageData
        }
        link="/blog/"
        buttonLabel="SEE MORE BLOGS"
      />
    </>
  );
}

export const query = graphql`
  query {
    strapiKingstonLocation {
      Hero {
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        Text
        Button {
          Custom_Link_Slug
          Label
        }
      }
      Store_Quick_Facts {
        Contact_Email
        Contact_Phone
        Location_Adress
        Location_City
      }

      Hours_Of_Op_Banner {
        Day_And_Hours {
          Day
          Hours
        }
        Address
        Address_Link
        Order_Online_Link_Slug
        Title
        long
        lat
      }
      Category_Section {
        Title
        Block {
          Link_Slug
          Title
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      Info_Banner_Type_A {
        Content
        Title
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Blog_Banner_Middle {
        Article_Link {
          slug
          Title
          Preview_Text
          BannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      FAQ {
        FAQs {
          Answer
          Question
        }
        Title
      }
      Blog_Banner_Bottom {
        Content
        Title
        Blog_Button_Link {
          Button_Label
          Link_Slug
        }
        Background_Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    strapiLocationsSearchComponent {
      Location_With_Search {
        Title
        Location_Single {
          Link_Slug
          Location_Address
          Location_Subtitle
          Location_Title
          Monday_To_Saturday_Hours
          Sunday_Hours
        }
      }
    }
  }
`;
